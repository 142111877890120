<template>
  <div>
    <div class="card shadow-sm mb-2">
      <div class="justify-content-between pl-0 pt-3 pb-3 ">
        <div class="col-6">
          <h5 class="e-text-red">
            1. Information and Instruction - General
          </h5>
        </div>
        <div class="col-6 ml-3">
          <font-awesome-icon icon="square"/> Score range :  5 High 0 low. However, internal audit scores may reflect a score of less than zero, due to the possibility of negative scores.
          <br>
          <font-awesome-icon icon="square"/> Due to a change in the score calculation method, all VPIs at the start of January 2022 will start with a score of 3.
          <br>
          <font-awesome-icon icon="square"/> If an internal audit was conducted but no VPI data were available in the report, the score 3 is registered.
          <br>
          <font-awesome-icon icon="square"/> If there is an inspection, that score is entered in the column for the month of the examination.
          <br>
          <font-awesome-icon icon="square"/> The score will continue to be reflected and maintained in the following months until the next examination.
          <br>
          <font-awesome-icon icon="square"/> The maximum score for each of PSC, ICBT and IA is 5. The minimum score is 0 (except IA).
          <br>
          <font-awesome-icon icon="square"/> For Internal audit (IA) scores may appear as (-) negative scores. (Based on SMS-H-07-P5)
          <br>
          <font-awesome-icon style="margin-left: -1px" icon="star"/> Please note that the score calculation method will be reviewed during the course of operation and is subject to change and update.
        </div>
      </div>
    </div>
    <div class="card shadow-sm mb-2">
      <div class="justify-content-between pl-0 pt-3 pb-3 ">
        <div class="col-12">
          <h5 class="e-text-red">
            2. Information - PSC Score determination arrangements table
          </h5>
        </div>
        <div class="col-12 ml-3">
          If there is more than one inspection in a month, the average of the inspection results is used as the score for the month.
          <table class="small e-tbl w-50 text-center">
            <thead class="bg-white" style="position: sticky; top: 0; z-index: 2">
            <tr>
              <th class="align-middle" style="width: 1em" scope="col">Number of Deficiency per inspection</th>
              <th class="align-middle" style="width: 1em" scope="col">Converted score for this VPI sheet</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td class="align-middle">0 Deficiency</td>
              <td class="align-middle">5</td>
            </tr>
            <tr>
              <td class="align-middle">1 Deficiency</td>
              <td class="align-middle">4.5</td>
            </tr>
            <tr>
              <td class="align-middle">2 Deficiency</td>
              <td class="align-middle">4</td>
            </tr>
            <tr>
              <td class="align-middle">3 Deficiency</td>
              <td class="align-middle">3.5</td>
            </tr>
            <tr>
              <td class="align-middle">4 Deficiency</td>
              <td class="align-middle">3</td>
            </tr>
            <tr>
              <td class="align-middle">5 Deficiency</td>
              <td class="align-middle">2.5</td>
            </tr>
            <tr>
              <td class="align-middle">6 Deficiency</td>
              <td class="align-middle">2</td>
            </tr>
            <tr>
              <td class="align-middle">7 Deficiency</td>
              <td class="align-middle">1.5</td>
            </tr>
            <tr>
              <td class="align-middle">8 Deficiency</td>
              <td class="align-middle">1</td>
            </tr>
            <tr>
              <td class="align-middle">9 Deficiency</td>
              <td class="align-middle">0.5</td>
            </tr>
            <tr>
              <td class="align-middle">More than 9 Def</td>
              <td class="align-middle">0</td>
            </tr>
            <tr>
              <td class="align-middle">Detain</td>
              <td class="align-middle">0</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="card shadow-sm mb-2">
      <div class="justify-content-between pl-0 pt-3 pb-3 ">
        <div class="col-12">
          <h5 class="e-text-red">
            3. Information - ICBT Score determination arrangements table
          </h5>
        </div>
        <div class="col-12 ml-3">
          If there is more than one inspection in a month, the average of the inspection results is used as the score for the month.
          <table class="small e-tbl w-50 text-center">
            <thead class="bg-white" style="position: sticky; top: 0; z-index: 2">
            <tr>
              <th class="align-middle" style="width: 1em" scope="col">ICBT score in Vamos</th>
              <th class="align-middle" style="width: 1em" scope="col">Converted score for this VPI sheet</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td class="align-middle">A - a</td>
              <td class="align-middle">5</td>
            </tr>
            <tr>
              <td class="align-middle">A - b</td>
              <td class="align-middle">4.8</td>
            </tr>
            <tr>
              <td class="align-middle">A - c</td>
              <td class="align-middle">4.6</td>
            </tr>
            <tr>
              <td class="align-middle">A - d</td>
              <td class="align-middle">4.4</td>
            </tr>
            <tr>
              <td class="align-middle">A - e</td>
              <td class="align-middle">4.2</td>
            </tr>
            <tr>
              <td class="align-middle">B - a</td>
              <td class="align-middle">4</td>
            </tr>
            <tr>
              <td class="align-middle">B - b</td>
              <td class="align-middle">3.8</td>
            </tr>
            <tr>
              <td class="align-middle">B - c</td>
              <td class="align-middle">3.6</td>
            </tr>
            <tr>
              <td class="align-middle">B - d</td>
              <td class="align-middle">3.4</td>
            </tr>
            <tr>
              <td class="align-middle">B - e</td>
              <td class="align-middle">3.2</td>
            </tr>
            <tr>
              <td class="align-middle">C - a</td>
              <td class="align-middle">3</td>
            </tr>
            <tr>
              <td class="align-middle">C - b</td>
              <td class="align-middle">2.8</td>
            </tr>
            <tr>
              <td class="align-middle">C - c</td>
              <td class="align-middle">2.6</td>
            </tr>
            <tr>
              <td class="align-middle">C - d</td>
              <td class="align-middle">2.4</td>
            </tr>
            <tr>
              <td class="align-middle">C - e</td>
              <td class="align-middle">2.2</td>
            </tr>
            <tr>
              <td class="align-middle">D - a</td>
              <td class="align-middle">2</td>
            </tr>
            <tr>
              <td class="align-middle">D - b</td>
              <td class="align-middle">1.8</td>
            </tr>
            <tr>
              <td class="align-middle">D - c</td>
              <td class="align-middle">1.6</td>
            </tr>
            <tr>
              <td class="align-middle">D - d</td>
              <td class="align-middle">1.4</td>
            </tr>
            <tr>
              <td class="align-middle">D - e</td>
              <td class="align-middle">1.2</td>
            </tr>
            <tr>
              <td class="align-middle">E - a</td>
              <td class="align-middle">1</td>
            </tr>
            <tr>
              <td class="align-middle">E - b</td>
              <td class="align-middle">0.8</td>
            </tr>
            <tr>
              <td class="align-middle">E - c</td>
              <td class="align-middle">0.6</td>
            </tr>
            <tr>
              <td class="align-middle">E - d</td>
              <td class="align-middle">0.4</td>
            </tr>
            <tr>
              <td class="align-middle">E - e</td>
              <td class="align-middle">0.2</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="card shadow-sm mb-2">
      <div class="justify-content-between pl-0 pt-3 pb-3 ">
        <div class="col-12">
          <h5 class="e-text-red">
            4. Information -  Internal audit Score determination
          </h5>
        </div>
        <div class="col-12 ml-3">
          If there is more than one inspection in a month, the average of the inspection results is used as the score for the month.
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {

  name: 'VPIIntroduction',

}
</script>

<style scoped lang="scss">

</style>
